import React from "react"
import styled from "styled-components"

import SEO from "../../components/shared/SEO"
import Layout from "../../dsm/layout"
import Inner from "../../dsm/layout/Inner"
import Card from "./card/LayoutA"
import PageHeader from "../../layouts/PageHeader"
import Pagination from "../../components/Pagination"
import CategoryNavigation from "./categories/categoriesNavigation"
import getFlexiOptionsContent from "../../options/getFlexiOptionsContent"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ItemsArchive = ({ pageContext }) => {
  const { nodes, pageNumber, hasNextPage, itemsPerPage, allItems } = pageContext
  const { lightSkin } = getFlexiOptionsBranding()
  const { itemsArchivePage } = getFlexiOptionsContent()

  const heading =
    itemsArchivePage.pageHeader ||
    process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL.charAt(0).toUpperCase() +
      process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL.slice(1)

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Layout>
      <SEO
        title={`${heading} | Page ${pageNumber}`}
        description={`${heading} | Page ${pageNumber}`}
        keywords={[heading]}
      />
      <PageHeader
        title={heading}
        introText={itemsArchivePage.introText}
        crumbs={[
          { pathname: "/", crumbLabel: "Home" },
          {
            pathname: `/${process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL}/`,
            crumbLabel: heading,
          },
        ]}
      />
      <Wrapper lightSkin={lightSkin} mainBgColor={mainBgColor}>
        <Inner>
          <div>
            <h4>{heading}</h4>

            {nodes && nodes.map((item) => <Card key={item.id} item={item} />)}

            <Pagination
              allItems={allItems}
              pageNumber={pageNumber}
              hasNextPage={hasNextPage}
              itemsPerPage={itemsPerPage}
              totalPages={Math.ceil(allItems.length / itemsPerPage)}
            />
          </div>
          <div className="sidebar">
            <div className="categories-widget">
              <h4>Select a Category</h4>
              <CategoryNavigation />
            </div>
          </div>
        </Inner>
      </Wrapper>
    </Layout>
  )
}
export default ItemsArchive

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? mainBgColor
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[500]};
  .categories-widget {
    position: sticky;
    top: 20px;
  }
  .inner {
    display: grid;
    grid-template-columns: 2fr 300px;
    grid-gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h4 {
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .inner {
      grid-template-columns: 1fr;
    }
  }
`
